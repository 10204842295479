//footer

.footer{
    position: relative;
    overflow: hidden;
    background: $primary;
    background-size: 12%;
    padding: 80px 0 50px  0;

    &:before{
        content: '';
        width: 20rem;
        height: 100%;
        background: url(../img/pat.png) repeat;
        position: absolute;
        top: 40%;
        left: -100px;
        opacity: 0.7;
    }

    h3{
        color: #ebebeb;
        font-weight: 500;
        font-size: 1.1rem;
        margin: 40px 0;
    }

    p{
        color: rgb(182, 184, 198);
        margin: 0;
    }

    &-list{
        column-count: 1;

        @include media-breakpoint-up(md) {
            column-count: 2;
        }

        &__item{
            padding: 2px 0;
            line-height: 2;
            a, span{
                color: rgb(182, 184, 198);
                cursor: pointer;
                transition: 0.3s ease-in-out;

                &:hover{
                    color: $secondary;
                    padding-left: 5px;
                }
            }
        }
    }

    &-link{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 30px 0;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }

        &__txt{
            color: rgb(182, 184, 198);
            font-size: 0.7rem;
            font-weight: 400;
        }
    }

    &-tel{
        pointer-events: auto;
        cursor: default;

        @include media-breakpoint-up(sm) {
            pointer-events: none;
        }
        &:hover{
            color: #fff;
        }
    }
}