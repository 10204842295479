html{
    height: 100%;
}

body{
    background: #ffffff;
    height: 100%;
    font-family: $secondaryFont;
    font-size: 14px;

    &.noScroll{overflow: hidden;}
}

//scroll-bar

::-webkit-scrollbar {
    width: 8px;
  }

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background: #f2f2f2;
  }

::-webkit-scrollbar-thumb {
    background: $primary; 
    border-radius: 50px;
  }

::selection {
    background: #0D0E0E;
    color: #fff;
}

  .project, .app{
    height: 100%;
}

.wrapper{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 100%;
}

.main-content{
    flex: 1;
    section{
        padding: 70px 0;
        position: relative;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            padding: 100px 0;
        }

        ul li{
            &:before{
                content: "✔";
                display: inline-block;
                margin-right: 10px;
                vertical-align: middle;
                color: $primary;
            }
        }
        
    }
}

//typography

h1, h2, h3, h4, h5, h6{
    color: $tertiary;
    font-family: $primaryFont;
}

p{
    font-size: 14px;
    color: #878a8f;
    line-height: 1.7;
    font-weight: 400;
    font-family: $secondaryFont;
}

strong, b{
    font-weight: 500;
    color: $primary;
}

h2{
    font-weight: 700;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    line-height: 1.1;
    letter-spacing: -1px;

    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 3.3rem;
    }
}

h3{
    text-transform: none;
    color: $tertiary;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 0;
    margin-bottom: 20px;
    font-family: $secondaryFont;

    &.spaced{
        font-size: 1rem;
        text-transform: uppercase;
        color:#878a8f;
        letter-spacing: 3px;
        font-weight: 500;
    }
}


//list
ul{
    margin: 0;
    padding: 0;
    list-style: none;

    li{
        color: #878a8f;
        line-height: 1.5;
    }
}

//links

a{
    text-decoration: none!important;;
    color: $secondary;
    transition: 0.3s ease-in-out;

    &:hover{
        color: $primary;
    }
}