//mobile-nav

.mobile-nav{
    position: fixed;
    top: 0;
    left:0;
    height: 100%;
    width: 100%;
    z-index: 10;
    transform-origin: top right;
    background: url(../img/yesmak_logo_white.svg)$primary no-repeat center 95%;
    background-size: 35%;
    transition: 0.4s ease-in-out;
    visibility: hidden;
    opacity: 0;
    display: block;

    @include media-breakpoint-up(lg) {
        display: none;
    }

    &.open{
        visibility: visible;
        opacity: 1;
    }

    &__inner{
        position: relative;
        padding-top: 55px;

        .icon-close{
            position: absolute;
            top: 10px;
            right: 10px;
        }

        ul{
            padding: 20px;
            margin: 0;
            list-style: none;

            li a, li span{
                display: block;
                border-radius: 30px;
                padding: 5px 20px;
                background: rgba(0,0,0,.2);
                color: rgba(255,255,255,.8);
                text-decoration: none;
                font-weight: 400;
                margin-top: 5px;
                transition: 0.3s ease-in-out;
                cursor: pointer;

                &:active,&:hover{
                    background: $secondary;
                    color: #000;
                }
            }
        }
    }
}